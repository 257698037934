<template>
  <div class="rightLink">
    <div class="bottomDiv" style="padding: 0px 25px;height:750px">
      <h5 class="title" style="margin-bottom: 5px;">
        <i class="glyphicon glyphicon-list-alt"></i> 页面权限管理
      </h5>
      <el-button
        class="buttonPri"
        style="width: 110px"
        @click="
          addRoleDiaLogFlag = true;
          DiaLogType = 1;
          DiaLogFlagTitle = '添加权限';
        "
        size="small"
      >
        <i class="glyphicon glyphicon-plus"></i> 添加权限</el-button
      >
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%;"
        height="630px"
        :header-row-style="{ color: '#333' }"
      >
        <!-- <el-table-column prop="id" sortable label="ID" width="60" /> -->
        <el-table-column prop="node" label="页面编号" width="85" />
        <el-table-column prop="path" label="页面路径" />
        <el-table-column prop="grand_parent_path" label="所属分类" />
        <el-table-column prop="perm_mod" label="一级目录" />
        <el-table-column prop="perm_list" label="二级目录" />
        <el-table-column prop="perm_info" label="三级目录" />
        <el-table-column label="操作" min-width="184" fixed="right">
          <template #default="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-edit"></i> 编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-trash"></i> 删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 弹框 -->
      <div v-dialogdrag>
        <el-dialog
          :before-close="closeDialog"
          v-model="addRoleDiaLogFlag"
          :title="DiaLogFlagTitle"
        >
          <el-form :model="dialogFrom">
            <!-- <el-form-item label="页面标题" label-width="120px">
            <el-input
              clearable
              v-model="dialogFrom.perm_info"
              autocomplete="off"
            ></el-input>
          </el-form-item> -->
          <el-row :gutter="20">
            <el-col :span="2"></el-col>
            <el-col :span="14">
              <el-form-item label="编号" label-width="120px">
                <el-input
                  clearable
                  v-model.number="dialogFrom.node"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="路径" label-width="120px">
                <el-input
                  clearable
                  v-model="dialogFrom.path"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="所属模块" label-width="120px">
                <el-input
                  clearable
                  v-model="dialogFrom.grand_parent_path"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="一级目录" label-width="120px">
                <el-input
                  clearable
                  v-model="dialogFrom.perm_mod"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="二级目录" label-width="120px">
                <el-input
                  clearable
                  v-model="dialogFrom.perm_list"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="三级目录" label-width="120px">
                <el-input
                  clearable
                  v-model="dialogFrom.perm_info"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="closeDialog">取消</el-button>
              <el-button type="primary" class="buttonPri" @click="submitDiaLog"
                >确认</el-button
              >
            </span>
          </template>
        </el-dialog>
      </div>
      <!-- <el-pagination
        background
        layout="prev, pager, next, total"
        :total="total"
        @current-change="handleCurrentChange"
        class="fenye"
      >
      </el-pagination> -->
    </div>
  </div>
</template>
<style scoped></style>

<script>
// onMounted
import { onMounted, reactive, toRefs } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import {
  search_pagepermission,
  add_pagepermission,
  edit_pagepermission,
  delete_pagepermission,
} from "@/request/api";
export default {
  name: "routerAccess",
  setup() {
    const data = reactive({
      loading: false,
      tableData: [],
      searchData: {
        // 搜索
        keyword: "",
      },
      page: 1,
      total: 0,
      current_page: 10,
      addRoleDiaLogFlag: false,
      DiaLogFlagTitle: "添加权限",
      DiaLogType: 1, // 1为添加 2为编辑
      dialogFrom: {
        label: "",
        path: "",
        node: "2",
        parent: "",
        grand_parent_path: "",
        grandParentPath: "",
        perm_mod: "",
        perm_list: "",
        perm_info: "",
      },
      editId: "",
    });
    // 关闭弹框
    const closeDialog = () => {
      data.addRoleDiaLogFlag = false;
      data.dialogFrom = {
        label: "",
        path: "",
        node: "2",
        parent: "",
        grand_parent_path: "",
        perm_mod: "",
        perm_list: "",
        perm_info: "",
      };
    };
    // 获取权限列表
    const fetchData = () => {
      // data.loading = true;
      const dataa = {
        rows_per_page: data.current_page,
        page: data.page,
      };
      search_pagepermission(dataa)
        .then((res) => {
          if (res) {
            data.tableData = res.data;
            data.loading = false;
            // data.total = parseInt(res.table);
          } else {
            data.loading = false;
          }
        })
        .catch(() => {
          data.tableData = [];
          data.loading = false;
        });
    };
    // 添加 / 编辑
    const submitDiaLog = () => {
      const dataa = data.dialogFrom;
      if (data.DiaLogType == 1) {
        add_pagepermission(dataa)
          .then((res) => {
            if (res) {
              fetchData();
              data.addRoleDiaLogFlag = false;
              ElMessage.success("添加成功");
            } else {
              data.loading = false;
              data.addRoleDiaLogFlag = false;
              ElMessage.error("操作失败");
            }
          })
          .catch(() => {
            ElMessage.error("操作失败");
            data.tableData = [];
            data.loading = false;
            data.addRoleDiaLogFlag = false;
          });
      } else if (data.DiaLogType == 2) {
        const dataa1 = {
          id: data.editId,
          perm_mod: data.dialogFrom.perm_mod,
          perm_list: data.dialogFrom.perm_list,
          perm_info: data.dialogFrom.perm_info,
          path: data.dialogFrom.path,
          grand_parent_path: data.dialogFrom.grand_parent_path,
          node: data.dialogFrom.node,
          // const dataa1 = data.dialogFrom;`
        };

        edit_pagepermission(dataa1)
          .then((res) => {
            data.addRoleDiaLogFlag = false;
            if (res) {
              fetchData();
            } else {
              ElMessage.error("操作失败");
            }
          })
          .catch(() => {
            data.addRoleDiaLogFlag = false;
            data.tableData = [];
            data.loading = false;
          });
      }
      // dataa={};
    };
    // 删除
    const handleDelete = (index, row) => {
      ElMessageBox.confirm("您确认删除此权限吗？", "警告", {
        confirmButtonText: "是的",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const dataa = {
          id: row.id,
        };
        delete_pagepermission(dataa)
          .then((res) => {
            if (res) {
              fetchData();
              ElMessage.success("删除成功");
              data.loading = false;
            } else {
              ElMessage.error("操作失败");
              data.loading = false;
            }
          })
          .catch(() => {
            ElMessage.error("操作失败");
            data.tableData = [];
            data.loading = false;
          });
      });
    };
    // 打开编辑弹框
    const handleEdit = (index, row) => {
      data.addRoleDiaLogFlag = true;
      data.DiaLogType = 2;
      data.DiaLogFlagTitle = "编辑权限";
      data.dialogFrom = {
        node: row.node,
        path: row.path,
        grand_parent_path: row.grand_parent_path,
        perm_mod: row.perm_mod,
        perm_list: row.perm_list,
        perm_info: row.perm_info,
      };
      data.editId = row.id;
    };
    // 分页
    const handleCurrentChange = (val) => {
      data.page = val;
      fetchData();
    };
    onMounted(() => {
      fetchData();
    });
    return {
      ...toRefs(data),
      fetchData,
      closeDialog,
      submitDiaLog,
      handleEdit,
      handleDelete,
      handleCurrentChange,
    };
  },
};
</script>
